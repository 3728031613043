import React from 'react';
import { t } from 'i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PatientAppointment from '../../containers/Patient/Patient/Widget/PatientAppointment';

class ModalPatientAppointment extends React.Component {
    constructor(props) {
        super(props);

        this._forceUpdate = false;
        this.state = this.initModal();
    }

    initModal = () => {
        return {
            loading: true,
            patient: null
        };
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillReceiveProps() {
        this.setState(this.initModal());
        this._forceUpdate = true;
    }

    componentDidUpdate() {
        if (!this.state.patient && this._forceUpdate) {
            this._forceUpdate = false;
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    render() {
        const { patient } = this.props;

        return (
            <Modal size="xxl" isOpen={this.props.isOpen} toggle={this.onToggle}>
                <ModalHeader toggle={this.onToggle}>
                    {patient ? (<strong>{t('patients.patient:modal:title-appointments', { patientName: patient.fullName })}</strong>) : t('patients.patient:info')}
                </ModalHeader>

                <ModalBody>
                    {patient && <PatientAppointment {...this.props} actions={['edit', 'delete']} patienId={patient.id} toggleModals={this.onToggleAll} />}
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={this.onToggle}>{t('common:Close')}</Button>
                </ModalFooter>
            </Modal >
        );
    }

    fire = () => this.setState({ modal: true });
    onToggle = () => this.props.toggle();
    onToggleAll = () => this.props.toggleAll();
}

export default ModalPatientAppointment;